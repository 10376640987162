@import url("https://fonts.googleapis.com/css2?family=Anton&family=Heebo:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Anton", sans-serif;
  font-size: 85px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}

h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}

h3 {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

h4 {
  font-family: "Anton", sans-serif;
  font-size: 27px;
  font-weight: 500;
  margin: 0;
  margin-top: 90px;
}

h5 {
  font-family: "Anton", sans-serif;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

h6 {
  font-family: "Heebo", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

p {
  font-family: "Heebo", sans-serif;
  font-size: 19px;
  font-weight: 350;
  margin: 0;
}

li {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 350;
  margin: 0;
  line-height: 28px;
}

a {
  font-family: "Heebo", sans-serif;
  font-weight: 350;
}
