@keyframes backgroundColorPalette {
  0% {
    background: #f7d1cd;
  }
  100% {
    background: #ecd0d6;
  }
}

@keyframes textAppear {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  56% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes plusSpin {
  0% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(0deg);
  }
  36% {
    transform: rotate(360deg);
  }
  70% {
    transform: rotate(0deg);
  }
}

.header {
  animation-name: backgroundColorPalette;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  height: 100vh;
  position: relative;
  z-index: -1;
}

.header-dropdown {
  animation: 2s textAppear;
}

.header-text {
  width: 86%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-4%, 32%);
}

.header-text h2 {
  margin-top: -5px;
  margin-bottom: 5px;
}

.header-subtext h6 {
  font-size: 34px;
  font-weight: 1000;
  color: #ffffff;
  animation: 2s plusSpin;
}

.header-subtext {
  display: flex;
  column-gap: 10px;
}

.header-dropdown h3 {
  margin-top: 6.4px;
}

/* HEADER -> MOBILE ======================================= */

@media (max-width: 963px) {
  .header h1 {
    font-size: 8.8vw;
  }
  .header h2 {
    font-size: 3.1vw;
  }
}

@media (max-width: 850px) {
  .header-subtext h3 {
    font-size: 2.8vw;
  }
}

@media (max-width: 768px) {
  .header-text {
    transform: translate(-4%, 48%);
  }
}

@media (max-width: 640px) {
  .header-text {
    transform: translate(-4%, 40%);
  }
  .header h1 {
    font-size: 12vw;
  }
  .header h2 {
    font-size: 4.3vw;
  }
  .header-subtext h3 {
    font-size: 3.4vw;
  }
  .header-subtext h6 {
    font-size: 5.1vw;
  }
}

@media (max-width: 425px) {
  .header-text {
    transform: translate(-2%, 50%);
  }
  .header h2 {
    font-size: 4.9vw;
  }
  .header-subtext h3 {
    font-size: 4.4vw;
  }
  .header-subtext h6 {
    font-size: 6.8vw;
  }
}
