.about h4 {
  color: #f7d1cd;
}

.about h5 {
  color: #735d78;
  text-align: center;
  margin-top: 20px;
}

.about-underline {
  height: 3px;
  background-color: #f7d1cd;
  width: 55%;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  .about-container {
    display: flex;
    flex-wrap: row nowrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 35px;
  }
  .about-container img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.about-container p {
  line-height: 34px;
}

.tech-icon p {
  font-weight: 450;
  text-align: center;
  margin-top: 8px;
}

@media (min-width: 769px) {
  .tech-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: row nowrap;
    justify-content: space-between;
    text-align: center;
  }
  .tech-icon img {
    height: 3.6vw;
  }
  .tech-icon p {
    font-size: 1vw;
  }
}

/* ABOUT -> MOBILE ======================================= */

@media (max-width: 770px) {
  .about-container p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 599px) {
  .about-container img {
    width: 100px;
    height: 100px;
    float: left;
    padding-right: 20px;
  }
  .about-container p {
    font-size: 15px;
    line-height: 22px;
  }
}

@media (max-width: 335px) {
  .about-container img {
    display: none;
  }
}

/* TECH SKILLS -> MOBILE ================================== */

@media (max-width: 768px) {
  .tech-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 20px;
    text-align: center;
  }
  .tech-icon img {
    height: 45px;
  }

  .tech-icon p {
    font-size: 12px;
  }
}

@media (max-width: 410px) {
  .tech-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 19px;
    row-gap: 10px;
    text-align: center;
  }
  .tech-icon img {
    height: 45px;
  }

  .tech-icon p {
    font-size: 10px;
  }
}

@media (max-width: 248px) {
  .tech-container {
    margin-top: 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    row-gap: 0px;
    text-align: center;
  }
  .tech-icon img {
    display: none;
  }

  .tech-icon p {
    font-size: 12px;
  }
}
