.footer {
  background-color: #a8c7d0;
}

.footer-left a {
  text-decoration: none;
  font-size: 15px;
  color: white;
  font-weight: 500;
  margin-bottom: 8px;
}

.footer-left a:hover {
  color: #735d78;
}

.footer-button {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: solid 2px white;
  padding: 8px 6px;
}

.footer-button:hover {
  background-color: #735d78;
  border: solid 2px #735d78;
}

.footer-icons img {
  width: 26px;
  height: 26px;
  margin-left: 12px;
  margin-bottom: 28px;
}

/* FOOTER -> MOBILE ================================ */

@media (max-width: 469px) {
  .footer-left {
    display: none;
  }
  .footer-right {
    text-align: center;
    padding: 20px 0 30px 15px;
  }
  .footer-icons img {
    margin: 0 6px 20px 6px;
  }
}

/* FOOTER -> DESKTOP ================================ */

@media (min-width: 470px) {
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .footer-right {
    text-align: right;
    padding: 25px 0 10px 15px;
    margin: 5px 82px 0px 0;
  }
  .footer-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 30px 0 20px 80px;
    line-height: 1em;
  }
}
