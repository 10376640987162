.portfolio {
  text-align: right;
}

.portfolio h4 {
  color: #ecd0d6;
}

.portfolio-underline {
  height: 3px;
  background-color: #ecd0d6;
  width: 55%;
  float: right;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (min-width: 769px) {
  .portfolio-container {
    margin-top: 50px;
    display: flex;
    flex-wrap: row nowrap;
    justify-content: space-between;
    text-align: center;
  }
  .portfolio-container img {
    width: 19vw;
  }
}

.portfolio-container img {
  border: 1px solid rgb(128, 128, 128);
}

.portfolio-box p {
  font-size: 15px;
  margin-bottom: 6px;
}

.portfolio-box h5 {
  color: #ecd0d6;
  margin-top: 10px;
}

.portfolio-box h5:hover {
  cursor: pointer;
  color: #735d78;
}

.portfolio-box h6 {
  font-size: 30px;
  font-weight: 1000;
  color: #735d78;
  margin-top: -4px;
  margin-bottom: -14px;
  transition: transform 0.8s ease-in-out;
}

.portfolio-box h6:hover {
  cursor: pointer;
  transform: rotate(360deg);
}

/* PORTFOLIO DROPDOWNS ====================================== */

.section-line {
  background-color: black;
  height: 1px;
  margin: 10px 0 8px 0;
}

.code-list {
  display: flex;
  flex-direction: column;
}

.code-list p {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 2px;
}

.portfolio-box a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: white;
  background-color: #735d78;
  width: 38%;
  margin: 0 auto;
  padding: 6px 4px 6px 4px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.portfolio-box a:hover {
  background-color: #ecd0d6;
}

@media (min-width: 716px) {
  .dropdown {
    width: 19vw;
  }
}

.dropdown {
  margin: 0 auto;
}

/* PORTFOLIO -> MOBILE ======================================= */

@media (max-width: 768px) {
  .dropdown p {
    font-size: 13.8px;
  }
  .portfolio-container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;
    text-align: center;
  }
  .portfolio-container img {
    width: 40vw;
  }
  .portfolio-container img {
    width: 40vw;
  }
  .dropdown {
    width: 37vw;
  }
  .dropdown p {
    font-size: 16.4px;
  }
  .portfolio-box a {
    font-size: 15px;
    padding: 6px 0 6px 0;
  }
}

@media (max-width: 500px) {
  .dropdown p {
    font-size: 14px;
  }
  .portfolio-box a {
    font-size: 12px;
    padding: 6px 6px 6px 6px;
  }
}

@media (max-width: 350px) {
  .portfolio-container {
    margin-top: 50px;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 25px;
    text-align: center;
  }
  .portfolio-container img {
    width: 80vw;
  }
  .dropdown {
    width: 75vw;
  }
}
